import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    menu: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    tag: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    sysList: {
      data: null,
      locale: '',
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    content: {
      data: null,
      locale: '',
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.system.appMenuGetListPending] = (state, action) => {
  return state.setIn('menu.loading'.split('.'), true);
}

reducer.prototype[actionType.system.appMenuGetListRejected] = (state, action) => {
  return state.setIn('menu.loading'.split('.'), false)
              .setIn('menu.error.hasError'.split('.'), true)
              .setIn('menu.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.appMenuGetListFulfilled] = (state, action) => {
  return state.setIn('menu.loading'.split('.'), false)
              .setIn('menu.loaded'.split('.'), true)
              .setIn('menu.loadedOn'.split('.'), new Date())
              .setIn('menu.error.hasError'.split('.'), false)
              .setIn('menu.error.lastError'.split('.'), null)
              .setIn('menu.data'.split('.'), action.payload);
}

reducer.prototype[actionType.system.tagGetListPending] = (state, action) => {
  return state.setIn('tag.loading'.split('.'), true);
}

reducer.prototype[actionType.system.tagGetListRejected] = (state, action) => {
  return state.setIn('tag.loading'.split('.'), false)
              .setIn('tag.error.hasError'.split('.'), true)
              .setIn('tag.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.tagGetListFulfilled] = (state, action) => {
  return state.setIn('tag.loading'.split('.'), false)
              .setIn('tag.loaded'.split('.'), true)
              .setIn('tag.loadedOn'.split('.'), new Date())
              .setIn('tag.error.hasError'.split('.'), false)
              .setIn('tag.error.lastError'.split('.'), null)
              .setIn('tag.data'.split('.'), action.payload);
}

reducer.prototype[actionType.system.sysListGetPending] = (state, action) => {
  return state.setIn('sysList.loading'.split('.'), true);
}

reducer.prototype[actionType.system.sysListGetRejected] = (state, action) => {
  return state.setIn('sysList.loading'.split('.'), false)
              .setIn('sysList.error.hasError'.split('.'), true)
              .setIn('sysList.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.sysListGetFulfilled] = (state, action) => {
  return state.setIn('sysList.loading'.split('.'), false)
              .setIn('sysList.loaded'.split('.'), true)
              .setIn('sysList.loadedOn'.split('.'), new Date())
              .setIn('sysList.error.hasError'.split('.'), false)
              .setIn('sysList.error.lastError'.split('.'), null)
              .setIn('sysList.locale'.split('.'), action.payload.locale)
              .setIn('sysList.data'.split('.'), action.payload.data);
}

reducer.prototype[actionType.system.contentListGetPending] = (state, action) => {
  return state.setIn('content.loading'.split('.'), true);
}

reducer.prototype[actionType.system.contentListGetRejected] = (state, action) => {
  return state.setIn('content.loading'.split('.'), false)
              .setIn('content.error.hasError'.split('.'), true)
              .setIn('content.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.contentListGetFulfilled] = (state, action) => {
  return state.setIn('content.loading'.split('.'), false)
              .setIn('content.loaded'.split('.'), true)
              .setIn('content.loadedOn'.split('.'), new Date())
              .setIn('content.error.hasError'.split('.'), false)
              .setIn('content.error.lastError'.split('.'), null)
              .setIn('content.locale'.split('.'), action.payload.locale)
              .setIn('content.data'.split('.'), action.payload.data);
}