import { ActionType } from 'redux-promise-middleware';

export const actionType = {
  ui: {
    setBusyStatus:                'UI_SETBUSYSTATUS',
    setLanguage:                  'UI_SETLANGUAGE',
  },
  page: {
    home: {
      setViewMode:              'UI_PAGE_HOME_SETVIEWMODE'
    },
    gallery: {
      upload: {
        setSelectedFile:        'UI_PAGE_GALLERY_UPLOAD_SETSELECTEDFILE',
        setForm:                'UI_PAGE_GALLERY_UPLOAD_SETFORM',
        setFormField:           'UI_PAGE_GALLERY_UPLOAD_SETFORMFIELD',
        setFormValidation:      'UI_PAGE_GALLERY_UPLOAD_SETFORMVALIDATION',
        setFormValidationField: 'UI_PAGE_GALLERY_UPLOAD_SETFORMVALIDATIONFIELD',
      }
    }
  },
  session: {
    authPending:                  `SESSION_AUTH_${ActionType.Pending}`,
    authFulfilled:                `SESSION_AUTH_${ActionType.Fulfilled}`,
    authRejected:                 `SESSION_AUTH_${ActionType.Rejected}`,

    myProfileGetPending:          `SESSION_MYPROFILEGET_${ActionType.Pending}`,
    myProfileGetFulfilled:        `SESSION_MYPROFILEGET_${ActionType.Fulfilled}`,
    myProfileGetRejected:         `SESSION_MYPROFILEGET_${ActionType.Rejected}`,

    myProfileUpdatePending:       `SESSION_MYPROFILEUPDATE_${ActionType.Pending}`,
    myProfileUpdateFulfilled:     `SESSION_MYPROFILEUPDATE_${ActionType.Fulfilled}`,
    myProfileUpdateRejected:      `SESSION_MYPROFILEUPDATE_${ActionType.Rejected}`,

    logout:                       `SESSION_LOGOUT`
  },

  system: {
    appMenuGetListPending:        `SYSTEM_APPMENUGETLIST_${ActionType.Pending}`,
    appMenuGetListFulfilled:      `SYSTEM_APPMENUGETLIST_${ActionType.Fulfilled}`,
    appMenuGetListRejected:       `SYSTEM_APPMENUGETLIST_${ActionType.Rejected}`,

    tagGetListPending:            `SYSTEM_TAGGETLIST_${ActionType.Pending}`,
    tagGetListFulfilled:          `SYSTEM_TAGGETLIST_${ActionType.Fulfilled}`,
    tagGetListRejected:           `SYSTEM_TAGGETLIST_${ActionType.Rejected}`,

    sysListGetPending:            `SYSTEM_SYSLISTGET_${ActionType.Pending}`,
    sysListGetFulfilled:          `SYSTEM_SYSLISTGET_${ActionType.Fulfilled}`,
    sysListGetRejected:           `SYSTEM_SYSLISTGET_${ActionType.Rejected}`,

    contentListGetPending:        `SYSTEM_CONTENTLISTGET_${ActionType.Pending}`,
    contentListGetFulfilled:      `SYSTEM_CONTENTLISTGET_${ActionType.Fulfilled}`,
    contentListGetRejected:       `SYSTEM_CONTENTLISTGET_${ActionType.Rejected}`,
  },

  gallery: {
    ownerPhotoGetListPending:       `GALLERY_OWNERPHOTOGETLIST_${ActionType.Pending}`,
    ownerPhotoGetListFulfilled:     `GALLERY_OWNERPHOTOGETLIST_${ActionType.Fulfilled}`,
    ownerPhotoGetListRejected:      `GALLERY_OWNERPHOTOGETLIST_${ActionType.Rejected}`,

    ownerPhotoInsertItem:           `GALLERY_OWNERPHOTOINSERTITEM`,
    ownerPhotoUpdateItem:           `GALLERY_OWNERPHOTOUPDATEITEM`,
    ownerPhotoDeleteItem:           `GALLERY_OWNERPHOTODELETEITEM`,

    showCasePhotoGetListPending:    `GALLERY_SHOWCASEPHOTOGETLIST_${ActionType.Pending}`,
    showCasePhotoGetListFulfilled:  `GALLERY_SHOWCASEPHOTOGETLIST_${ActionType.Fulfilled}`,
    showCasePhotoGetListRejected:   `GALLERY_SHOWCASEPHOTOGETLIST_${ActionType.Rejected}`,

    showCasePhotoGetItemPending:    `GALLERY_SHOWCASEPHOTOGETITEM_${ActionType.Pending}`,
    showCasePhotoGetItemFulfilled:  `GALLERY_SHOWCASEPHOTOGETITEM_${ActionType.Fulfilled}`,
    showCasePhotoGetItemRejected:   `GALLERY_SHOWCASEPHOTOGETITEM_${ActionType.Rejected}`,
  },

  cart: {
    cartGetListPending:             `CART_GETLIST_${ActionType.Pending}`,
    cartGetListFulfilled:           `CART_GETLIST_${ActionType.Fulfilled}`,
    cartGetListRejected:            `CART_GETLIST_${ActionType.Rejected}`,

    cartItemAddPending:             `CART_ITEMADD_${ActionType.Pending}`,
    cartItemAddFulfilled:           `CART_ITEMADD_${ActionType.Fulfilled}`,
    cartItemAddRejected:            `CART_ITEMADD_${ActionType.Rejected}`,

    cartItemRemovePending:          `CART_ITEMREMOVE_${ActionType.Pending}`,
    cartItemRemoveFulfilled:        `CART_ITEMREMOVE_${ActionType.Fulfilled}`,
    cartItemRemoveRejected:         `CART_ITEMREMOVE_${ActionType.Rejected}`,

    cartItemRemoveAllPending:       `CART_ITEMREMOVEALL_${ActionType.Pending}`,
    cartItemRemoveAllFulfilled:     `CART_ITEMREMOVEALL_${ActionType.Fulfilled}`,
    cartItemRemoveAllRejected:      `CART_ITEMREMOVEALL_${ActionType.Rejected}`,

    cartSubmitPaymentPending:       `CART_SUBMITPAYMENT_${ActionType.Pending}`,
    cartSubmitPaymentFulfilled:     `CART_SUBMITPAYMENT_${ActionType.Fulfilled}`,
    cartSubmitPaymentRejected:      `CART_SUBMITPAYMENT_${ActionType.Rejected}`,
  },

  order: {
    ownerOrderGetListPending:       `ORDER_OWNERORDERGETLIST_${ActionType.Pending}`,
    ownerOrderGetListFulfilled:     `ORDER_OWNERORDERGETLIST_${ActionType.Fulfilled}`,
    ownerOrderGetListRejected:      `ORDER_OWNERORDERGETLIST_${ActionType.Rejected}`,

    ownerOrderItemGetListPending:   `ORDER_OWNERORDERITEMGETLIST_${ActionType.Pending}`,
    ownerOrderItemGetListFulfilled: `ORDER_OWNERORDERITEMGETLIST_${ActionType.Fulfilled}`,
    ownerOrderItemGetListRejected:  `ORDER_OWNERORDERITEMGETLIST_${ActionType.Rejected}`,
  },
  
}