import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import Helper from '@justnice/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    ownerPhoto: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    showCasePhoto: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.gallery.ownerPhotoGetListPending] = (state, action) => {
  return state.setIn('ownerPhoto.loading'.split('.'), true);
}

reducer.prototype[actionType.gallery.ownerPhotoGetListRejected] = (state, action) => {
  return state.setIn('ownerPhoto.loading'.split('.'), false)
              .setIn('ownerPhoto.error.hasError'.split('.'), true)
              .setIn('ownerPhoto.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.gallery.ownerPhotoGetListFulfilled] = (state, action) => {
  return state.setIn('ownerPhoto.loading'.split('.'), false)
              .setIn('ownerPhoto.loaded'.split('.'), true)
              .setIn('ownerPhoto.loadedOn'.split('.'), new Date())
              .setIn('ownerPhoto.error.hasError'.split('.'), false)
              .setIn('ownerPhoto.error.lastError'.split('.'), null)
              .setIn('ownerPhoto.data'.split('.'), action.payload);
}

reducer.prototype[actionType.gallery.ownerPhotoInsertItem] = (state, action) => {
  let assets = state.getIn('ownerPhoto.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(assets)) {
    assets = {
      cur_page: 0,
      data: [],
      page_size: 0,
      page_total: 1
    };
  }
  else {
    assets = Helper.deepCopy(assets);
  }

  assets.data.unshift(action.payload);
  
  return state.setIn('ownerPhoto.loading'.split('.'), false)
              .setIn('ownerPhoto.loaded'.split('.'), true)
              .setIn('ownerPhoto.loadedOn'.split('.'), new Date())
              .setIn('ownerPhoto.error.hasError'.split('.'), false)
              .setIn('ownerPhoto.error.lastError'.split('.'), null)
              .setIn('ownerPhoto.data'.split('.'), assets);
}

reducer.prototype[actionType.gallery.ownerPhotoUpdateItem] = (state, action) => {
  let assets = state.getIn('ownerPhoto.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(assets)) {
    assets = {
      cur_page: 0,
      data: [],
      page_size: 0,
      page_total: 1
    };
  }
  else {
    assets = Helper.deepCopy(assets);
  }
  // Check if the item added existing or not, if yes, increase the qty by 1, 
  // else push the item into the List
  let idx = assets.data.findIndex(item => item._id === action.payload._id);
  if(idx >= 0) {
    assets.data[idx] = action.payload;
  }
  
  return state.setIn('ownerPhoto.loading'.split('.'), false)
              .setIn('ownerPhoto.loaded'.split('.'), true)
              .setIn('ownerPhoto.loadedOn'.split('.'), new Date())
              .setIn('ownerPhoto.error.hasError'.split('.'), false)
              .setIn('ownerPhoto.error.lastError'.split('.'), null)
              .setIn('ownerPhoto.data'.split('.'), assets);
}

reducer.prototype[actionType.gallery.ownerPhotoDeleteItem] = (state, action) => {
  let assets = state.getIn('ownerPhoto.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(assets))
    return state; // no items, nothing to remove, just return the state

  assets = Helper.deepCopy(assets);
  let idx = assets.data.findIndex(item => item._id === action.payload);
  if(idx >= 0) {
    assets.data.splice(idx, 1);
  }

  return state.setIn('ownerPhoto.loading'.split('.'), false)
              .setIn('ownerPhoto.loaded'.split('.'), true)
              .setIn('ownerPhoto.loadedOn'.split('.'), new Date())
              .setIn('ownerPhoto.error.hasError'.split('.'), false)
              .setIn('ownerPhoto.error.lastError'.split('.'), null)
              .setIn('ownerPhoto.data'.split('.'), assets);
}

reducer.prototype[actionType.gallery.showCasePhotoGetListPending] = (state, action) => {
  return state.setIn('showCasePhoto.loading'.split('.'), true);
}

reducer.prototype[actionType.gallery.showCasePhotoGetListRejected] = (state, action) => {
  return state.setIn('showCasePhoto.loading'.split('.'), false)
              .setIn('showCasePhoto.error.hasError'.split('.'), true)
              .setIn('showCasePhoto.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.gallery.showCasePhotoGetListFulfilled] = (state, action) => {
  let data = state.getIn('showCasePhoto.data'.split('.'));
  
  if(!Helper.isNotNullAndUndefined(data)) {
    // If no data, use whatever in payload
    data = action.payload;
  }
  else {
    // if have data, copy it
    data = Helper.deepCopy(data);

    // If assets is null, init as empty array
    if(!Helper.isNotNullAndUndefined(data.assets))
      data.assets = [];

    // Only payload assets have data
    if(Helper.arrayHasItem(action.payload.assets)) {
      // If payload page is 0, then override assets
      if(action.payload.page === 0)
        data.assets = action.payload.assets;
      else {
        // If not page 0, then concat the assets if the payload page is greater then data page
        if(action.payload.page > data.page)
          data.assets = data.assets.concat(action.payload.assets);
      }
    }
    else {
      if(action.payload.page === 0) {
        data.assets = [];
      }
    }

    data.page = action.payload.page;
    data.page_size = action.payload.page_size;
    data.total_page = action.payload.total_page;
  }
  return state.setIn('showCasePhoto.loading'.split('.'), false)
              .setIn('showCasePhoto.loaded'.split('.'), true)
              .setIn('showCasePhoto.loadedOn'.split('.'), new Date())
              .setIn('showCasePhoto.error.hasError'.split('.'), false)
              .setIn('showCasePhoto.error.lastError'.split('.'), null)
              .setIn('showCasePhoto.data'.split('.'), data);
}


reducer.prototype[actionType.gallery.showCasePhotoGetItemPending] = (state, action) => {
  return state.setIn('showCasePhoto.loading'.split('.'), true);
}

reducer.prototype[actionType.gallery.showCasePhotoGetItemRejected] = (state, action) => {
  return state.setIn('showCasePhoto.loading'.split('.'), false)
              .setIn('showCasePhoto.error.hasError'.split('.'), true)
              .setIn('showCasePhoto.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.gallery.showCasePhotoGetItemFulfilled] = (state, action) => {
  let assets = state.getIn('showCasePhoto.data.assets'.split('.'));
  if(!Helper.isNotNullAndUndefined(assets))
    assets = [];
  else
    assets = Helper.deepCopy(assets);

  assets.unshift(...action.payload);

  return state.setIn('showCasePhoto.loading'.split('.'), false)
              .setIn('showCasePhoto.loaded'.split('.'), true)
              .setIn('showCasePhoto.loadedOn'.split('.'), new Date())
              .setIn('showCasePhoto.error.hasError'.split('.'), false)
              .setIn('showCasePhoto.error.lastError'.split('.'), null)
              .setIn('showCasePhoto.data.assets'.split('.'), assets);
}
