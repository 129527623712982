import {applyMiddleware, createStore} from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from '../reducers';
import StateTransform from './transform';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['page', 'gallery', 'system', 'order'],
  transforms: [StateTransform()]
};

const stateSyncConfig = {
  // Don't sync for persist/PERSIST and persist/REHYDRATE actions
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
};

const isServer = typeof window === "undefined";

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = process.env.NODE_ENV.toLowerCase() === 'production' ? 
                    [
                      promise, 
                      thunk,
                      // Remove the createLogger in production build
                      // createLogger()
                    ]
                    :
                    [
                      promise, 
                      thunk, 
                      createLogger()
                    ];

// Only if in client env then add createStateSyncMiddleware.
// Adding createStateSyncMiddleware in server side will cause error during production build
if(!isServer) {
  middleware.push(createStateSyncMiddleware(stateSyncConfig));
} 

let store = createStore(persistedReducer, applyMiddleware(...middleware));

// Only if in client env then invoke initMessageListener.
// Invoke initMessageListener in server side will cause error during production build
if(!isServer) {
  initMessageListener(store);
}

let persistor = persistStore(store);

export {
  store,
  persistor
};