import {actionType} from '../actions/actionType';
import { fromJS } from 'immutable';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return (
    {
      home: {
        viewMode: 'PARALLAX' // PARALLAX or GRID
      },
      gallery: {
        upload: {
          selectedFile: null,
          form: {
            title: '',
            imageFormat: '',
            imageMedium: '',
            description: '',
            takenOn: '',
            location: '',
            geoLocation: '',
            cameraModel: '',
            cameraFocalLength: '',
            cameraShutterSpeed: '',
            cameraAperture: '',
            cameraIso: '',
            imageWidth: '',
            imageHeight: '',
            price1: '',
            price2: '',
            price3: '',
            introUrl: '',
            tags: []
          },
          formValidation: {
            selectedFile: {error: false, touched: false, message: ''},
            title: {error: false, touched: false, message: ''},
            imageFormat: {error: false, touched: false, message: ''},
            imageMedium: {error: false, touched: false, message: ''},
            takenOn: {error: false, touched: false, message: ''},
            cameraModel: {error: false, touched: false, message: ''},
            cameraFocalLength: {error: false, touched: false, message: ''},
            cameraAperture: {error: false, touched: false, message: ''},
            cameraIso: {error: false, touched: false, message: ''},
            imageWidth: {error: false, touched: false, message: ''},
            imageHeight: {error: false, touched: false, message: ''},
            price1: {error: false, touched: false, message: ''},
            price2: {error: false, touched: false, message: ''},
            price3: {error: false, touched: false, message: ''},
            introUrl: {error: false, touched: false, message: ''}
          }
        }
      }
    }
  );
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.page.home.setViewMode] = (state, action) => {
  return state.setIn('home.viewMode'.split('.'), action.payload);
}

reducer.prototype[actionType.page.gallery.upload.setSelectedFile] = (state, action) => {
  return state.setIn('gallery.upload.selectedFile'.split('.'), action.payload);
}

reducer.prototype[actionType.page.gallery.upload.setForm] = (state, action) => {
  return state.setIn('gallery.upload.form'.split('.'), action.payload);
}

reducer.prototype[actionType.page.gallery.upload.setFormField] = (state, action) => {
  return state.setIn(`gallery.upload.form.${action.payload.field}`.split('.'), action.payload.value);
}

reducer.prototype[actionType.page.gallery.upload.setFormValidation] = (state, action) => {
  return state.setIn('gallery.upload.formValidation'.split('.'), action.payload);
}

reducer.prototype[actionType.page.gallery.upload.setFormValidationField] = (state, action) => {
  return state.setIn(`gallery.upload.formValidation.${action.payload.field}`.split('.'), action.payload.validation);
}