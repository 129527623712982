import {actionType} from '../actions/actionType';
import { fromJS } from 'immutable';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return (
    {
      lastError: '',
      busy: false,
      lang: 'en'
    }
  );
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

// reducer.prototype[actionType.ui.resetSearch] = (state, action) => {
//   state = initialState();
//   return state;
// }

reducer.prototype[actionType.ui.setBusyStatus] = (state, action) => {
  return state.set('busy', action.payload);
}

reducer.prototype[actionType.ui.setLanguage] = (state, action) => {
  return state.set('lang', action.payload);
}
