import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import Helper from '@justnice/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    ownerOrder: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.order.ownerOrderGetListPending] = (state, action) => {
  return state.setIn('ownerOrder.loading'.split('.'), true);
}

reducer.prototype[actionType.order.ownerOrderGetListRejected] = (state, action) => {
  return state.setIn('ownerOrder.loading'.split('.'), false)
              .setIn('ownerOrder.error.hasError'.split('.'), true)
              .setIn('ownerOrder.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.order.ownerOrderGetListFulfilled] = (state, action) => {
  return state.setIn('ownerOrder.loading'.split('.'), false)
              .setIn('ownerOrder.loaded'.split('.'), true)
              .setIn('ownerOrder.loadedOn'.split('.'), new Date())
              .setIn('ownerOrder.error.hasError'.split('.'), false)
              .setIn('ownerOrder.error.lastError'.split('.'), null)
              .setIn('ownerOrder.data'.split('.'), action.payload);
}

reducer.prototype[actionType.order.ownerOrderItemGetListPending] = (state, action) => {
  return state.setIn('ownerOrder.loading'.split('.'), true);
}

reducer.prototype[actionType.order.ownerOrderItemGetListRejected] = (state, action) => {
  return state.setIn('ownerOrder.loading'.split('.'), false)
              .setIn('ownerOrder.error.hasError'.split('.'), true)
              .setIn('ownerOrder.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.order.ownerOrderItemGetListFulfilled] = (state, action) => {
  let orders = state.getIn('ownerOrder.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(orders))
    return state; // no items, nothing to remove, just return the state

  orders = Helper.deepCopy(orders);
  let idx = orders.findIndex(item => item.id === action.payload.orderId);
  if(idx >= 0) {
    orders[idx].order_items = action.payload.data;
  }

  return state.setIn('ownerOrder.loading'.split('.'), false)
              .setIn('ownerOrder.loaded'.split('.'), true)
              .setIn('ownerOrder.loadedOn'.split('.'), new Date())
              .setIn('ownerOrder.error.hasError'.split('.'), false)
              .setIn('ownerOrder.error.lastError'.split('.'), null)
              .setIn('ownerOrder.data'.split('.'), orders);
}