import { combineReducers } from 'redux';
import ui from './uiReducer';
import session from './sessionReducer';
import system from './systemReducer';
import page from './pageReducer';
import gallery from './galleryReducer';
import cart from './cartReducer';
import order from './orderReducer';

export default combineReducers({
  ui,
  session,
  system,
  page,
  gallery,
  cart,
  order
})