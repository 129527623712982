import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import Helper from '@justnice/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    cart: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.cart.cartGetListPending] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), true);
}

reducer.prototype[actionType.cart.cartGetListRejected] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.error.hasError'.split('.'), true)
              .setIn('cart.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.cart.cartGetListFulfilled] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.loaded'.split('.'), true)
              .setIn('cart.loadedOn'.split('.'), new Date())
              .setIn('cart.error.hasError'.split('.'), false)
              .setIn('cart.error.lastError'.split('.'), null)
              .setIn('cart.data'.split('.'), action.payload);
}

reducer.prototype[actionType.cart.cartItemAddPending] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), true);
}

reducer.prototype[actionType.cart.cartItemAddRejected] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.error.hasError'.split('.'), true)
              .setIn('cart.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.cart.cartItemAddFulfilled] = (state, action) => {
  let cartItems = state.getIn('cart.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(cartItems))
    cartItems = [];
  else
    cartItems = Helper.deepCopy(cartItems);

  cartItems.push(action.payload);

  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.loaded'.split('.'), true)
              .setIn('cart.loadedOn'.split('.'), new Date())
              .setIn('cart.error.hasError'.split('.'), false)
              .setIn('cart.error.lastError'.split('.'), null)
              .setIn('cart.data'.split('.'), cartItems);
}

reducer.prototype[actionType.cart.cartItemRemovePending] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), true);
}

reducer.prototype[actionType.cart.cartItemRemoveRejected] = (state, action) => {
  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.error.hasError'.split('.'), true)
              .setIn('cart.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.cart.cartItemRemoveFulfilled] = (state, action) => {
  let cartItems = state.getIn('cart.data'.split('.'));
  if(!Helper.isNotNullAndUndefined(cartItems))
    return state; // no items, nothing to remove, just return the state

  cartItems = Helper.deepCopy(cartItems);
  let idx = cartItems.findIndex(item => item.id === action.payload);
  if(idx >= 0) {
    cartItems.splice(idx, 1);
  }

  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.loaded'.split('.'), true)
              .setIn('cart.loadedOn'.split('.'), new Date())
              .setIn('cart.error.hasError'.split('.'), false)
              .setIn('cart.error.lastError'.split('.'), null)
              .setIn('cart.data'.split('.'), cartItems);
}

reducer.prototype[actionType.cart.cartItemRemoveAllFulfilled] = (state, action) => {
  
  return state.setIn('cart.loading'.split('.'), false)
              .setIn('cart.loaded'.split('.'), true)
              .setIn('cart.loadedOn'.split('.'), new Date())
              .setIn('cart.error.hasError'.split('.'), false)
              .setIn('cart.error.lastError'.split('.'), null)
              .setIn('cart.data'.split('.'), []);
}

reducer.prototype[actionType.session.logout] = (state, action) => {
  return initialState();
}