import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    auth: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    myProfile: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.session.authPending] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), true);
}

reducer.prototype[actionType.session.authRejected] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), false)
              .setIn('auth.error.hasError'.split('.'), true)
              .setIn('auth.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.session.authFulfilled] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), false)
              .setIn('auth.loaded'.split('.'), true)
              .setIn('auth.loadedOn'.split('.'), new Date())
              .setIn('auth.error.hasError'.split('.'), false)
              .setIn('auth.error.lastError'.split('.'), null)
              .setIn('auth.data'.split('.'), action.payload);
}

reducer.prototype[actionType.session.myProfileGetPending] = 
reducer.prototype[actionType.session.myProfileUpdatePending] = 
(state, action) => {
  return state.setIn('myProfile.loading'.split('.'), true);
}

reducer.prototype[actionType.session.myProfileGetRejected] = 
reducer.prototype[actionType.session.myProfileUpdateRejected] = 
(state, action) => {
  return state.setIn('myProfile.loading'.split('.'), false)
              .setIn('myProfile.error.hasError'.split('.'), true)
              .setIn('myProfile.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.session.myProfileGetFulfilled] = 
reducer.prototype[actionType.session.myProfileUpdateFulfilled] = 
(state, action) => {
  return state.setIn('myProfile.loading'.split('.'), false)
              .setIn('myProfile.loaded'.split('.'), true)
              .setIn('myProfile.loadedOn'.split('.'), new Date())
              .setIn('myProfile.error.hasError'.split('.'), false)
              .setIn('myProfile.error.lastError'.split('.'), null)
              .setIn('myProfile.data'.split('.'), action.payload);
}

reducer.prototype[actionType.session.logout] = (state, action) => {
  return initialState();
}